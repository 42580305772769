// import { firestoreAction } from 'vuexfire'
import _cloneDeep from 'lodash/cloneDeep'
import _forEach from 'lodash/forEach'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _merge from 'lodash/merge'
import _filter from 'lodash/filter'
import { bindCollection, bindDocument, unbind, setDoc, updateDoc, addDoc, deleteDoc, uploadFile, deleteFile } from '@/store/firebase'
import { putRecord, queueImageUpload, startUploadQueueProcessor } from '../localDb.js'

// Debug flag - set to true to enable debug logging
const DEBUG = true;

// Debug logging function
const debugLog = (...args) => {
  if (DEBUG) {
    console.log('[vuex:db.js]', ...args);
  }
};
import { bindDefaultRoots, bindNewRoot, bindReservedIDs, getDoc, getAsset, outsetaCheckAccount, outsetaGetToken } from '../firebase.js'
import { LogEvent, LogSetOrg } from '../analytics'
import { updateSchema } from '@/lib/schema'
// import { cond } from 'lodash'
import router from '@/router'


const vuexModule = {
  state: {
    config: {},
    schema: {},
    reservedIDs: [],
    projections: [],
    integrations: [],
    org: {},
    unit: {},
    units: [],
    assets: {},
    images: {},
    reports: {},
    tags: {},
    files: {},
    devices: [],
    parents: {},
    recordCount: 0,
    count: {
      assets: '...',
      images: '...',
      files: '...',
      tags: '...'
    }
  },
  mutations: {
    SET_PARENTS(state, data) {
      state.parents = _cloneDeep(data);
    },
    SET_SCHEMA(state, data) {
      // console.log(data)
      state.schema = _cloneDeep(data);
    },
    RECORD_COUNT(state, value) {
      state.recordCount = value;
    },
    SET_COUNT(state, value) {
      if (typeof value.assets !== 'undefined') state.count.assets = value.assets
      if (typeof value.images !== 'undefined') state.count.images = value.images
      if (typeof value.tags !== 'undefined') state.count.tags = value.tags
    }
  },
  getters: {
    testOrg(state) {
      return (state.org || {}).name === 'Test Org'
    },
    accountStatus(state) {
      let accountStage = state.org.accountInfo?.status || state.org.accountInfo?.outsetaStage
      if(accountStage) {
        if(['trial','active','overdue','expired'].includes(accountStage)) return accountStage
        if(accountStage === 'Trialing') return 'trial'
        if(accountStage === 'Subscribing') return 'active'
        if(accountStage === 'Past due') return 'overdue'
        return 'expired'
      }
      else return null
    },
    tagByCode: (state) => (code) => { 
      let tag = _find(state.tags, (t) => { 
        return t.code === code || t.id === code
      })
      return tag || null
    },
    imageByID: (state) => (id) => {
      let image = state.images[id]
      if (image) return image
      let k = Object.keys(state.images).find(iid => {
        if (state.images[iid].imageID === id) return true
        if (state.images[iid].id === id) return true
        return false
      })
      return state.images[k] || false
    },
    assetBranch: (state, getters) => (id) => {
      // assetBranch() returns ids of the asset and all its children

      // The array starts from the assetID of specified asset and then adds children in order of their parentage. 
      // NOTE: the resultant order of returned ids is such that children are always noted after their parents
      // this feature is used by calling functions for various purposes (eg: deleting assets or displaying in heirachal order) 

      let a = getters.assetByID(id)
      let list = [a.assetID]
      // let rootAssets = _filter(state.assets, { 'root': a.root })
      // let children = (pid) => {
      //   let cArray = _filter(rootAssets, { 'parent': pid })
      //   cArray.forEach(c => {
      //     list.push(c.assetID)
      //     children(c.assetID)
      //   })
      // }
      let children = (pid) => {
        let cArray = state.parents[pid] || []
        list = list.concat(cArray)
        cArray.forEach(c => { children(c) })
      }
      children(id)
      return list
    },
    assetPath: (state, getters) => (id) => {
      // if(!options) options = { load: false }
      let tree = []
      let add = (id) => {
        let a = getters.assetByID(id)
        let label = a.attributes?.label || null
        let type = a.attributes?.type || null
        tree.push({
          id: a.id,
          assetID: a.assetID,
          parent: a.parent,
          label,
          type,
          display: (label || '') + ' | ' + type
        })
      }
      add(id)
      for(let i = 1; i <= 10; i++) {
        let prev = tree[i-1]
        if(!prev.parent) break
        add(prev.parent)
      }
      return tree
    },
    assetByID: (state) => (id) => {
      let asset = state.assets[id]
      if (asset) return asset
      let k = Object.keys(state.assets).find(aid => {
        let a = state.assets[aid]
        if (a.assetID === id) return true
        if (a.id === id) return true
        return false
      })
      return state.assets[k] || false
    },
    assetsByPoint: (state) => (id) => {
      let assets = _filter(state.assets, (a) => {
        return a.points.findIndex(p => { return p.id === id }) !== -1
      })
      return assets
    },
    reportsByAsset: (state, getters) => (id) => {
      let asset = getters.assetByID(id)
      if (!asset) return false
      let reports = []
      Object.values(state.reports).forEach(r => {
        if (!r.assets) console.log('no assets in report', r)
        else if (r.assets.includes(asset.assetID)) reports.push(r)
      })
      reports.sort((a,b) => {
        return a.ts.seconds - b.ts.seconds
      })
      return reports
    },
    tagsByAsset: (state) => (id) => {
      let tags = {
        codes: [],
        str: ''
      }
      _forEach(state.tags, (t, code) => {
        if (parseInt(t.assetID) === parseInt(id)) {
          tags.codes.push(code)
          if (tags.str.length > 0) tags.str += ', '
          tags.str += code
        }
      })
      return tags
    },
    assetCondition: (state, getters) => (id) => {
      let schema = state.schema.merge
      if(!schema.attributes) return false
      let value = schema.attributes.find(a => { return a.type === '_cond'})
      let ts = schema.attributes.find(a => { return a.type === '_condTs'})
      let reports = getters.reportsByAsset(id)
      let condReports = _cloneDeep((reports|| []).filter(r => { return r.type === 'condition'}))
      condReports.sort((a, b) => {
        const aTs = a.ts.seconds;
        const bTs = b.ts.seconds;
        return bTs - aTs
      })
      let ind = condReports.findIndex(r => { return r.status?.status === 'approved' })
      if(ind === -1) ind = condReports.findIndex(r => { return r.status?.status === 'pending' })
      if(ind === -1 && condReports.length === 1) ind = 0
      if(ind === -1) return false
      
      let result = {
        report: condReports[ind],
        attributes: {}
      }
      if(ts) result.attributes[ts.key] = new Date(result.report.ts.seconds*1000).toISOString()
      if(value) result.attributes[value.key] = result.report.attributes.rating
      return result
    },
    deviceByID: (state) => (id) => {
      return state.devices.find(a => { return a.id === id })
    },
    newAssetID: (state) => (qty = 1) => {
      const assetIDs = new Set()
      for (const asset in state.assets) {
        assetIDs.add(state.assets[asset].assetID)
      }
      const reservedIDs = []
      for (const reservedID of state.reservedIDs) {
        reservedIDs.push(reservedID.id)
      }
      reservedIDs.sort()
      let ids = []
      for (const reservedID of reservedIDs) {
        if(ids.length >= qty) break
        if (!assetIDs.has(reservedID)) {
          if(qty === 1) return reservedID
          ids.push(reservedID)
        }
      }
      if(ids.length > 0) return ids
      return null
    },
    attributesByType: (state, getters) => {
      let schema = state.schema.merge
      let schemaAttributes = {}
      _forEach(schema.attributes, (a) => {
        schemaAttributes[a.key] = a
      })
      let commonAttributes = state.schema.std.common
      // console.log(schema, commonAttributes)
      let result = {}
      let debugName = null
      _forEach(schema.types, (type, key) => {
        let templateAtts = schema.templates[type.template]?.attributes || []
        let keys = _union(templateAtts, type.addAttrib)
        let attributes = {}
        let debug = (type.name === debugName)
        if(debug) console.log('debug', { type, templateAtts, keys })
        let attributeAdd = (att) => {
          let tak = att.tak || att.key
          if(!attributes[tak]) attributes[tak] = att
          else if(att.key !== tak) {
            // TODO: overrite duplicate attribute definition with custom key
            // console.log('duplicate key, using for ' + tak, 'using: ' + att.key) 
            attributes[tak] = att
          }
        }
        let tak_set = new Set()
        keys.forEach((k) => {
          let a = schemaAttributes[k] || null
          
          if(!a) {
            if(debug) console.log('specified attribute not found', k)
            return
          }
          a = _cloneDeep(a)
          
          if(!a.type) {
            // handle / log this error so the user can be notified of errors in their schema
            a.type = 'text'
            console.log('error: attribute definition missing type assuming text: ', k)
            // LogEvent('system_error', { key: 'att.type', details: 'attribute definition missing type; db:attributes' })
          }
          a.key = String(a.key).toLowerCase().trim()
          let tak = a.tak || a.key
          if(debug) console.log({k, a, tak})
          tak_set.add(tak)
          attributeAdd(a)
        })
        if(debug) console.log('tak_keys', tak_set)
        commonAttributes.forEach((k) => {
          if(!tak_set.has(k)) {
            let a = schemaAttributes[k] || null
            if(!a) return
            a = _cloneDeep(a)
            a.key = String(a.key).toLowerCase().trim()
            // console.log('add common attribute', a.key)
            attributeAdd(a)
          }
        })
        if(debug) console.log('attribute', Object.values(attributes))
        result[type.name] = Object.values(attributes)
        result[type.name].sort((a, b) => {
          return a.index - b.index
        })
        
      })
      // console.log(result)
      return result
    },
    reportsFiltered: (state, getters) => (filter, num = 0) => {
      let reports = []
      if(filter.sort === 'opened') {
        let recent = (getters.unitSettings.recent || {}).reports || []
        recent.forEach(report => {
          let r = state.reports[report.id]
          if(r) reports.push(r)
          else reports.push(report)
        })
      }
      else if (filter.sort === 'modified') {
        reports = Object.values(state.reports).sort((a, b) => {
          let tsA = a.modified?.ts
          let tsB = b.modified?.ts
          if(!tsA) tsA = Array.isArray(a.history) ? a.history[0].ts : a.ts
          if(!tsB) tsB = Array.isArray(b.history) ? b.history[0].ts : b.ts
          return tsB.seconds - tsA.seconds
        })
      }
      else if (['created', 'urgency'].includes(filter.sort)) {
        reports = Object.values(state.reports).sort((a, b) => {
          let tsA = a.ts?.seconds
          let tsB = b.ts?.seconds
          return tsB - tsA
        })
      }
      if(filter.sort === 'urgency') {
        reports.sort((a, b) => {
          let aPriority = (a.type === 'fault' && a.status?.status === 'unresolved') ? 6 : (a.type === 'condition' ? a.attributes?.rating : 0);
          let bPriority = (b.type === 'fault' && b.status?.status === 'unresolved') ? 6 : (b.type === 'condition' ? b.attributes?.rating : 0);
          if(filter.type !== 'condition') {
            if(aPriority < 3) aPriority = 0
            if(bPriority < 3) bPriority = 0
          } 
          return bPriority - aPriority
        })
      }
    
      // Apply filters
      reports = reports.filter(report => {
        // User filter
        if (report.archived && filter.sort !== 'opened') return false
        if (filter.users !== 'all' && report.userID !== filter.user) {
          return false
        }
        // Type filter
        if (filter.type !== 'all') {
          if (filter.type === 'issues') {
            if(!['fault', 'condition'].includes(report.type)) return false
            if(report.type === 'fault' && report.status?.status !== 'unresolved') return false
            if(report.type === 'condition' && (report.attributes.rating < 4 || report.status?.status === 'history')) return false
          }
          else if (filter.type === 'edits') {
            // console.log('type', r.type, r.status?.status)
            if(!['condition', 'edit_asset', 'archive_asset'].includes(report.type)) return false
            if(!['pending', 'proposed'].includes(report.status?.status)) return false
          }
          else if (filter.type === 'tasks') {
            if(!report.type.startsWith('task_')) return false
          }
          else if (filter.type === 'edit_asset' && report.type !== 'edit_asset') {
            if (!['edit_asset', 'archive_asset'].includes(report.type)) return false
          }
          else if (!report.type.includes(filter.type)) return false
        }
        
        // Status filter
        if (filter.status !== 'all' || ['edits'].includes(report.type)) {
          // Skip status filtering for notes
          if (report.type === 'note') return true
    
          const status = report.status?.status
          if (!status) return false
    
          // Handle status of special report types
          if (report.type === 'edits') {
            if (!['proposed', 'pending'].includes(status)) return false
          }
          else {
            // Check if status matches filter
            if (status !== filter.status) return false
          }
        }
        return true
      })
      
      // console.log('reports filtered', JSON.parse(JSON.stringify(reports)))

      if(num > 0 && reports.length > 0) reports = reports.slice(0, num)
      return reports
    }
  },
  actions: {
    loggedIn({ dispatch, rootState }) {
      dispatch('bindApp')
      let orgID = rootState.Auth.configUser.org
      if(orgID) dispatch('switchOrg', orgID)
    },
    logout({ dispatch }) {
      unbind()
      dispatch('loading', false)
    },
    async switchOrg({ dispatch, commit }, id) {
      commit('SET_COUNT', { images: 0, assets: 0 })
      // console.log('switchOrg', id)
      commit('SET_INIT', null)
      await dispatch('bindOrg', id)
    },
    switchUnit({ commit, dispatch, rootState, getters, state }, id) {
      if (typeof id !== 'string') {
        // console.log(getters.orgSettings)
        id = getters.orgSettings.currentUnit || (state.units[0] || {}).id
      }
      if (id === undefined || rootState.Util.org === undefined) {
        console.log('missing id', rootState.Util.org, id)
        return
      }
      commit('SET_ROOT_CACHE', [])
      commit('SET_INIT', { org: true, unit: false, assets: false, files: false })
      dispatch('bindUnit', id)
    },
    bindApp: async ({ }) => {
      bindDocument({ key: 'Db.config', documentRef: ['config', 'webapp'] })
    },
    bindOrg({ commit, dispatch, rootState }, org) {
      return bindDocument({ key: 'Db.org', documentRef: ['orgs', org] })
      .then(async () => { 
        commit('SET_INIT', { org: true })
        const promises = []
        // promises.push(bindDocument({ key: 'Db.org', documentRef: ['orgs', org] }))
        promises.push(bindCollection({ key: 'Db.units', collectionRef: ['orgs', org, 'units'] }).catch(error => { return console.error('Failed to bind units', error);}))
        promises.push(bindCollection({ key: 'Db.projections', collectionRef: ['orgs', org, 'projections'] }).catch(error => { return console.error('Failed to bind projections', error);}))
        promises.push(bindCollection({ key: 'Db.tags', collectionRef: ['orgs', org, 'tags'] }).catch(error => { return console.error('Failed to bind tags', error);}))
        promises.push(bindReservedIDs({ key: 'Db.reservedIDs', collectionRef: ['orgs', org, 'reservedIDs'], userID: rootState.Auth.configUser.id }).catch(error => { return console.error('Failed to bind reserveIDs', error);}))
        promises.push(bindCollection({ key: 'Db.integrations', collectionRef: ['orgs', org, 'integrations'] }).catch(error => { return console.error('Failed to bind integrations', error);}))
        await Promise.all(promises)
        dispatch('switchUnit')
        dispatch('loading', ['records'])
        LogSetOrg()
        outsetaCheckAccount()
        outsetaGetToken()
        updateSchema()
        startUploadQueueProcessor()
      })
      .catch(err => { 
        console.log(err) 
        dispatch('loading', false)
        commit('SET_INIT', { failed: true })
        setTimeout(() => router.push('/teamselect'), 500)
        commit('SET_ALERT', { variant: 'error', notice: "Failed to load org" })
      })
    },
    async bindUnit({ commit, rootState }, id) {
      try {
        const promises = []
        promises.push(bindDocument({ key: 'Db.unit', documentRef: ['orgs', rootState.Util.org, 'units', id] })
          .then(() => { commit('SET_INIT', { unit: true }) }).catch(err => { console.log(err) }))
        promises.push(bindDefaultRoots({ documentRef: ['orgs', rootState.Util.org, 'units', id] })
          .then(() => { commit('SET_INIT', { images: true, assets: true, files: true }) }).catch(err => { console.log(err) }))
        promises.push(bindCollection({ key: 'Db.devices', collectionRef: ['orgs', rootState.Util.org, 'units', id, 'devices'] })
          .then(() => { commit('SET_INIT', { devices: true }) }).catch(err => { console.log(err) }))
        await Promise.all(promises)
        // console.log('Bind Unit complete'); 
        commit('SET_INIT', { complete: true })
      } catch (e) {
        console.warn(`ERROR loading ${loading}`)
        console.warn(e)
      }
    },
    bindRoot({ state }, root) {
      if (root === null) return true
      // console.log('bindRoot', root, {unit: state.unit.name,  org: state.org.name})
      return bindNewRoot({ key: 'bind-roots', documentRef: ['orgs', state.org.id, 'units', state.unit.id], root })
    },
    async getAsset({}, assetID) {
      let asset = await getAsset(assetID)
      return asset
    },
    async getImage({ state }, imageID) {
      let image = null
      // console.log(imageID, imageID.length)
      if(imageID.length !== 20) image = await getDoc(['orgs', state.org.id, 'units', state.unit.id, 'images'], 'Db.images', ['imageID', '==', imageID])
      else { image = await getDoc(['orgs', state.org.id, 'units', state.unit.id, 'images', imageID], 'Db.images', null) }
      return image
    },
    getReport({ state }, reportID) {
      getDoc(['orgs', state.org.id, 'units', state.unit.id, 'reports', reportID], 'Db.reports')
    },
    setOrg({ state }, update) {
      let id = ((state.org || {}).id || 'demo')
      if("schema" in update && !state.org.schema && state.unit.schema) {
        let newSchema = _merge({}, state.unit.schema, update.schema)
        update.schema = newSchema
      }
      setDoc(['orgs', id], update)
    },
    setUnit({ state }, { id, update }) {
      if (id === undefined) console.warn('id undefined')
      setDoc(['orgs', state.org.id, 'units', id], update)
    },
    async setAsset({ state, getters }, { id, update }) {
      if (id === undefined) console.warn('id undefined')
      let asset = getters.assetByID(id)
      if(Object.keys(update.attributes || {}).length === 0) delete update.attributes
      let result = await setDoc(['orgs', state.org.id, 'units', state.unit.id, 'assets', asset.id], update)
      return result
    },
    setMap({ state }, { id, data }) {
      let update = { maps: {} }
      update.maps[id] = Object.assign((state.unit.maps || {})[id] || {}, data)
      setDoc(['orgs', state.org.id, 'units', state.unit.id], update)
    },
    // Handle only the data part of image updates
    async setImageData({ state, getters, dispatch }, data) {
      debugLog('setImageData called', { imageID: data.imageID });
      
      let exist = getters.imageByID(data.imageID)
      let existId = exist ? exist.id : false
      debugLog('Existing image found?', { exists: !!exist, existId });
      
      // Update asset references
      let afterAssets = data.assets
      if (afterAssets) {   // afterAssets may not exist if we are not changing asset list
        debugLog('Updating asset references', { afterAssets });
        let beforeAssets = exist ? (exist.assets || []) : []
        afterAssets.forEach(a => {
          let asset = getters.assetByID(a)
          if (asset) {
            let imageSet = new Set(asset.images || [])
            imageSet.add(data.imageID)
            dispatch('updateAsset', { id: asset.id, update: { images: [...imageSet] } })
          }
        })
        beforeAssets.forEach(a => {
          if (!afterAssets.includes(a)) {
            let asset = getters.assetByID(a)
            if (asset) {
              let imageSet = new Set(asset.images || [])
              imageSet.delete(data.imageID)
              dispatch('updateAsset', { id: asset.id, update: { images: [...imageSet] } })
            }
          }
        })
      }
      
      // Save the image data
      if (existId) {
        await setDoc(['orgs', state.org.id, 'units', state.unit.id, 'images', existId], data)
      } else {
        await addDoc(['orgs', state.org.id, 'units', state.unit.id, 'images'], data)
      }
      
      // Update cache
      dispatch('pushImageCache', data.imageID)
      
      return { status: 'success', imageID: data.imageID }
    },
    
    // Main entry point for image uploads
    async setImage({ dispatch, commit }, { data, file }) {
      debugLog('setImage called', { 
        imageID: data?.imageID, 
        hasFile: !!file, 
        fileSize: file?.size 
      });
      
      // If there's a file, use the queue system
      if (file) {
        debugLog('File provided, using queue system');
        try {
          // Add to queue and return immediately
          const result = await queueImageUpload(data, file);
          debugLog('Image queued successfully', { 
            imageID: data.imageID, 
            queueId: result.queueId 
          });
          return result;
        } catch (error) {
          debugLog('Failed to queue image upload', error);
          console.error('Failed to queue image upload', error);
          
          // Show error to user
          commit('SET_ALERT', { 
            notice: `<strong>Image Upload Failed</strong> for ${data.imageID}. <br />Error: ${error.message}`, 
            variant: 'danger', 
            timeout: 10000 
          });
          
          return Promise.reject(error);
        }
      } else {
        debugLog('No file provided, updating data only');
        // No file, just update the data
        return await dispatch('setImageData', data);
      }
    },
    deleteImage({ state, getters, dispatch }, imageID) {
      let i = getters.imageByID(imageID)
      if (!i) i = state.images.find((record) => { return record.id === imageID })
      if (!i) return console.warn('could not find image: ', imageID)

      let assetIDs = _cloneDeep(i.assets || []);

      let deleteRecord = () => {
        assetIDs.forEach(assetID => {
          let asset = getters.assetByID(assetID);
          let update = { images: _cloneDeep(asset.images) };
          let ind = update.images.findIndex(iid => {
            return iid === i.imageID;
          });
          if (ind !== -1) {
            update.images.splice(ind, 1);
            dispatch("updateAsset", { id: asset.id, update });
          }
        });
        deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'images', i.id])
      }
      if (typeof i.src === 'string') {
        if (!i.src.startsWith('gs:')) return deleteRecord()
        let storageRef = i.src.substr(4)
        console.log('deleteImage', storageRef)
        deleteFile(storageRef)
          .then(result => {
            console.log(result)
            deleteRecord()
          })
          .catch((e) => {
            let msg = e.data.message
            if (String(msg).includes('storage/object-not-found')) {
              deleteRecord()
              return console.warn('image not found in storage')
            }
            console.warn(e)
          })
      }
      else if (Array.isArray(i.src)) {
        let promiseArray = []
        i.src.forEach((src, i) => {
          let dbPath = src.split('/')
          if (dbPath[2] !== data.imageID) console.warn('invalid GS path: imageID doesnt match')
          promiseArray.push(deleteFile(src))
        })
        Promise.all(promiseArray)
          .catch((e) => {
            if (e.code === 'storage/object-not-found') return 0
            console.warn(e)
          })
          .finally(() => {
            deleteRecord()
          })
      }
      else deleteRecord()
    },
    async addAsset({ state }, data) {
      let asset = await addDoc(['orgs', state.org.id, 'units', state.unit.id, 'assets'], data)
      LogEvent('add_asset')
      return asset
    },
    deleteAsset({ state, getters }, id) {
      // handle related reports: remove asset from list or delete reports if only refering to this asset
      let reports = getters.reportsByAsset(id)
      reports.forEach(r => {
        if(r.assets.length === 1) deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'reports', r.id])
        else {
          const assets = r.assets.slice() // take a copy
          const index = assets.indexOf(id)
          if (index > -1) assets.splice(index, 1)
          dispatch("updateReport", { id: r.id, update: { assets } })
        }
      })
      // handle related images: remove asset from list or delete images if only refering to this asset
      let images = _filter(state.images, { 'assets': [id] })
      images.forEach(i => {
        if(i.assets.length === 1) deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'images', i.id])
        else {
          const assets = i.assets.slice() // take a copy
          const index = assets.indexOf(id)
          if (index > -1) assets.splice(index, 1)
          dispatch("updateImage", { id: i.id, update: { assets } })
        }
      })

      deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'assets', id])
      LogEvent('delete_asset')
    },

    /* changeAssetID: applys relevant changes to images, reports and children 
    *  that are held in store. This is to ensure the UI experience is reasonable in
    *  an 'offline' situation. Further checks and changes are applied on server.
    */
    changeAssetId({ state, getters, dispatch }, { oldId, newId }) {
      return new Promise((resolve, reject) => {
        let a = getters.assetByID(oldId);

        if (!a) reject(`Could not find asset: ${oldId}`)

        let imageIDs = a.images || [];
        let children = _cloneDeep(state.parents[oldId] || [])
        let id = a.id

        imageIDs.forEach(imageID => {
          let image = getters.imageByID(imageID);
          if (image) {
            let update = { assets: [], hotSpots: _cloneDeep(image.hotSpots || []) };
            image.assets.forEach((aid) => {
              if (aid === a.assetID) update.assets.push(newId)
              else update.assets.push(aid)
            });
            update.hotSpots.forEach((hs, i) => {
              if (hs.assetID === a.assetID) update.hotSpots[i].assetID = newId
            });
            dispatch("updateImage", { id: image.id, update });
          }
        });

        for (const reportID in state.reports) {
          const assets = state.reports[reportID].assets.slice() // take a copy
          if (assets.includes(oldId)) {
            assets[assets.indexOf(oldId)] = newId
            dispatch("updateReport", { id: reportID, update: { assets } })
          }
        }

        children.forEach(childID => {
          let child = getters.assetByID(childID);
          if (child) dispatch("updateAsset", { id: child.id, update: { parent: newId } });
        });
        // console.log({ id, update: { assetID: newId } })
        dispatch("updateAsset", { id, update: { assetID: newId } });
        resolve()
      })
    },
    async addReport({ state }, data) {
      let result = await addDoc(['orgs', state.org.id, 'units', state.unit.id, 'reports'], data)
      LogEvent('add_report')
      return result
    },
    updateReport({ state }, { id, update }) {
      if (id === undefined) return console.warn('id undefined')
      updateDoc(['orgs', state.org.id, 'units', state.unit.id, 'reports', id], update)
    },
    setDevice({ state }, data) {
      setDoc(['orgs', state.org.id, 'units', state.unit.id, 'devices', data.id], data)
    },
    setTag({ state }, { id, update }) {
      setDoc(['orgs', state.org.id, 'tags', id], update)
    },
    deleteDevice({ state }, id) {
      deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'devices', id])
    },

    // The functions below use 'update' instead of set with merge.
    // Update can overwrite nested fields, but set with merge cannot.
    // It would be worth investigating this. 
    updateAsset({ state }, { id, update }) {
      if (id === undefined) return console.warn('id undefined')
        // console.log(['orgs', state.org.id, 'units', state.unit.id, 'assets', id], update)
      return updateDoc(['orgs', state.org.id, 'units', state.unit.id, 'assets', id], update)
    },
    updateImage({ state }, { id, update }) {
      if (id === undefined) return console.warn('id undefined')
      updateDoc(['orgs', state.org.id, 'units', state.unit.id, 'images', id], update)
    },
    updateUnit({ state }, { id, update }) {
      if (id === undefined) return console.warn('id undefined')
      updateDoc(['orgs', state.org.id, 'units', id], update)
    },
    updateOrg({ state }, update) {
      let id = state.org.id
      let valid = true
      Object.keys(update).forEach(k => {
        let keyArray = k.split('.')
        if(keyArray.length < 2) valid = false 
      })
      if(!valid) {
        LogEvent('system_error', { key: 'db:updateOrg', details: 'updateOrg should have point reference', update })
        return
      }
      return updateDoc(['orgs', id], update)
    },
  },
}

export default vuexModule
